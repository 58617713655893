<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row mt-4">
			<div class="col-xs-12 col-lg-7 d-flex">
				<div class="h2 text-muted py-3">
					{{ translate('confirmation') }}
				</div>
			</div>
			<div class="col-xs-12 col-lg-5 d-flex">
				<div
					class="h4"
					:class="['xs','sm', 'md'].includes(windowWidth) ? 'mb-2 text-left' : 'my-auto ml-auto text-right'">
					<div class="text-primary">
						<span class="font-weight-bold">{{ translate('sponsor') }}: </span>
						<span>{{ sponsor.name }} </span>
						<span>({{ translate('id_word') }}: {{ sponsor.id }})</span>
						<p
							v-if="positionData !== ''">
							<span class="font-weight-bold">{{ translate('parent') }}: </span>
							<span>{{ positionData.parent }} </span>
							<span>({{ translate('leg') }}: {{ translate(positionData.leg) }})</span>
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="row mb-5">
			<div class="col-12 col-md-6">
				<ul class="list-group w-100 h-100">
					<li class="list-group-item h-auto">
						<div class="row">
							<div class="col">
								<div class="h5">
									{{ translate('product_step') }}
								</div>
							</div>
							<div class="col-auto text-center">
								<b-button
									variant="outline-primary"
									class="btn-sm my-auto ml-auto"
									:to="{ name: Object.keys(steps).find(item => steps[item].validation === 'product') }">
									{{ translate('edit') }}
								</b-button>
							</div>
						</div>
						<div class="row mt-3">
							<div
								v-if="!loadingProducts && products && packageName.length > 0"
								class="col-12">
								<b>
									{{ translate(packageName) }}
								</b>
								<!-- <div
									v-for="(item, key) in products"
									:key="key">
									<div
										v-if="typeof productsQty[item.attributes.sku] !== 'undefined' && productsQty[item.attributes.sku] > 0"
										class="row">
										<div class="col-5">
											{{ translate(item.attributes.code_name) }}
										</div>
										<div class="col-auto">
											{{ productsQty[item.attributes.sku] }}
										</div>
									</div>
								</div> -->
							</div>
							<div
								v-else-if="!loadingProducts && packageName.length === 0"
								class="col-12">
								<div>
									<b>
										{{ translate('membership') }}
									</b>
								</div>
							</div>
							<div
								v-else
								class="col-12 fade-in text-center d-flex justify-content-center align-items-center">
								<div class="col-12">
									<div class="h2">
										<i class="fa fa-fw fa-spinner fa-pulse" />
										<h4 class="mt-3">
											{{ translate('loading') }}
										</h4>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li class="list-group-item h-auto">
						<div class="row">
							<div class="col">
								<div class="h5">
									{{ translate('account_step') }}
								</div>
							</div>
							<div class="col-auto text-center">
								<b-button
									variant="outline-primary"
									class="btn-sm my-auto ml-auto"
									:to="{ name: Object.keys(steps).find(item => steps[item].validation === 'personal_information') }">
									{{ translate('edit') }}
								</b-button>
							</div>
						</div>
						<div class="row mt-3">
							<div class="col-auto">
								<p class="mb-0">
									<b>{{ form.first_name }} {{ form.last_name }}</b>
								</p>
								<!-- <p class="mb-0">
									{{ translate(form.gender) }}
								</p> -->
								<p class="mb-0">
									<i class="far fa-envelope mr-2" /> {{ form.email }}
								</p>
								<!-- <p class="mb-0">
									<i class="fas fa-birthday-cake mr-2" /> {{ $moment(form.birthdate).format(MMMDY_FORMAT) }}
								</p> -->
								<p class="mb-0">
									<i class="fas fa-mobile-alt mr-2" /> {{ form.mobile_number }}
								</p>
								<p
									v-if="!isCompany && requiredNinCountries.includes(country)"
									class="mb-0">
									<i class="fas fa-university mr-2" /> {{ form.national_identification_number }}
								</p>
								<div v-if="isCompany">
									<p class="mb-0">
										<i class="fas fa-university mr-2" /> {{ form.tax_id }}
									</p>
									<p class="mb-0">
										<i class="far fa-building mr-2" /> {{ form.company }}
									</p>
								</div>
								<p><i class="fas fa-user mr-2" /> {{ form.username }}</p>
							</div>
						</div>
					</li>
					<li class="list-group-item h-auto">
						<div class="row">
							<div class="col">
								<div class="h5">
									{{ translate('shipping_step') }}
								</div>
							</div>
							<div class="col-auto text-center">
								<b-button
									variant="outline-primary"
									class="btn-sm my-auto ml-auto"
									:to="{ name: Object.keys(steps).find(item => steps[item].validation === 'shipping') }">
									{{ translate('edit') }}
								</b-button>
							</div>
						</div>
						<div class="row mt-3">
							<div class="col-auto">
								<p class="mb-0">
									<b>{{ shipping.first_name }} {{ shipping.last_name }}</b>
								</p>
								<p class="mb-0">
									{{ shipping.address }}
								</p>
								<p class="mb-0">
									{{ shipping.address2 }}
								</p>
								<p class="mb-0">
									{{ shipping.city }}, {{ shipping.region_id }}, {{ shipping.postcode }}
								</p>
								<p class="mb-0">
									{{ translate(shipping.country_code) }}
								</p>
								<!-- <p
									v-if="countriesListForSignature.includes(country)"
									class="mb-0">
									{{ translate('signature_label') }}: {{ translate(shipping.signature_required.toString()) }}
								</p> -->
								<div
									v-if="Object.keys(shippingMethodInfo).length"
									class="mt-1 mb-0">
									<img
										v-if="shippingMethodInfo.image"
										:src="require(`@/assets/images/common/shipping/carriers/${shippingMethodInfo.image}`)"
										class="my-auto border"
										style="width: 45px; height: 30px;">
									<p class="text-muted d-inline">
										<span
											v-if="shippingMethodInfo.name"
											class="ml-1">
											{{ translate(shippingMethodInfo.name) }}
										</span>
										<small
											v-if="deliveryDays.length"
											class="font-italic">
											<br>{{ translate('estimated_date') }}{{ translate(deliveryDays) }}
										</small>
										<br>
										<small
											v-if="shippingNotice.length"
											class="font-italic"
											v-html="translate(shippingNotice)" />
									</p>
									<br>
								</div>
							</div>
						</div>
						<hr>
						<div class="row mt-2">
							<div class="col-12">
								<div class="h8">
									<b>{{ translate('autoship') }}</b>
								</div>
								<div
									v-if="autoship.has_autoship_products"
									class="mt-2">
									<div
										v-for="(item, index) in autoship.autoship_products"
										:key="index">
										<p class="mb-0">
											<span class="mr-2"> {{ item.qty }} </span> {{ translate(item.code_name) }}
										</p>
									</div>
									<p class="text-small mt-2 mb-0">
										{{ translate('autoship_disclaimer') }}
									</p>
								</div>
								<template v-else>
									{{ translate('no_autoship_selected') }}
								</template>
							</div>
						</div>
					</li>
					<li class="list-group-item h-auto">
						<div class="row">
							<div class="col">
								<div class="h5 mb-0">
									{{ translate('payment_step') }}
								</div>
								<small>{{ translate(paymentMethod.name) }}</small>
							</div>
							<div class="col-auto text-center">
								<b-button
									variant="outline-primary"
									class="btn-sm my-auto ml-auto"
									:to="{ name: Object.keys(steps).find(item => steps[item].validation === 'payment') }">
									{{ translate('edit') }}
								</b-button>
							</div>
						</div>
						<div class="row mt-3">
							<div class="col-auto">
								<p class="mb-0">
									<b>{{ billing.first_name }} {{ billing.last_name }}</b>
								</p>
								<p class="mb-0">
									{{ billing.address }}
								</p>
								<p class="mb-0">
									{{ billing.address2 }}
								</p>
								<p class="mb-0">
									{{ billing.city }}, {{ billing.region_id }}, {{ billing.postcode }}
								</p>
								<p class="mb-0">
									{{ translate(billing.country_code) }}
								</p>
							</div>
						</div>
						<div
							v-if="paymentMethod.name === 'purchaseorder'"
							class="row mt-1">
							<div class="col-auto">
								<p class="mb-0">
									<img
										:src="require(`@/assets/images/common/cc/logos/${cardLogo}.png`)"
										class="my-auto mr-1"
										style="width:45px; height:30px;">
									<span class="text-muted"> ****{{ paymentMethod.last_digits.length > 0 ? paymentMethod.last_digits : '' }}</span>
								</p>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="col-12 col-md-6 mt-3 mt-md-0">
				<div class="row h-100">
					<div class="col-12">
						<li class="list-group-item h-auto rounded">
							<div class="table-responsive my-0">
								<is-loading
									:loading-label="translate('loading')"
									:no-data-label="translate('data_not_found')"
									:loading="loadingCartTotals"
									:has-data="true"
									:class="loadingCartTotals ? 'mt-3' : ''" />
								<table
									v-if="!loadingCartTotals && hasCartTotals"
									class="table table-borderless m-0">
									<thead>
										<tr class="h5 bg-white text-gray-dark">
											<th colspan="4">
												{{ translate('product_details') }}
											</th>
										</tr>
									</thead>
									<thead>
										<tr class="text-primary text-center border-bottom bg-white">
											<th class="text-left">
												{{ translate('product') }}
											</th>
											<th>
												{{ translate('qty') }}
											</th>
											<th>
												{{ translate('bv') }}
											</th>
											<th class="text-right">
												{{ translate('subtotal') }}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="item in cartTotals.products"
											:key="item.item_id">
											<td class="text-left font-weight-bolder pb-1">
												{{ translate(item.code_name) }}
												<a
													v-if="productHasLink(item.sku)"
													:href="productsLinks[item.sku]"
													target="_blank"
													class="text-decoration-none link-primary">
													<i class="fas fa-info-circle" />
												</a>
											</td>
											<td class="text-center pb-1">
												{{ item.qty }}
											</td>
											<td class="text-center pb-1">
												{{ item.total_volume }}
											</td>
											<td class="text-right pb-1">
												{{ item.sub_total }}
											</td>
										</tr>
										<tr
											v-for="(item, index) in cartTotals.totals"
											:key="index">
											<td
												colspan="3"
												class="pb-0 pt-1 font-weight-bolder text-right">
												{{ translate(index) }}
											</td>
											<td
												v-if="item === '$0'"
												class="pt-1 text-right success-alt">
												{{ translate('free_item') }}
											</td>
											<td
												v-else
												class="pb-0 pt-1 text-right">
												{{ item }}
											</td>
										</tr>
										<tr>
											<td
												colspan="3"
												class="pb-1 pb-3 font-weight-bolder text-right text-primary h5">
												{{ translate('total') }}
											</td>
											<td class="pb-1 pb-3 text-right text-primary h5">
												{{ cartTotals.total }}
												<span
													v-if="typeof cartTotals.exchange !== 'undefined' && cartTotals.exchange.is_needed"
													class="font-weight-normal exchange-text"
													style="display: block; font-size:11px;">
													{{ cartTotals.exchange.total }}*
												</span>
											</td>
										</tr>
									</tbody>
								</table>
								<b-alert
									v-if="!loadingCartInfo && cartInfo.out_of_stock_code_names.length"
									class="mb-0"
									variant="warning"
									show>
									<span
										v-for="(code_name, index) in cartInfo.out_of_stock_code_names"
										:key="index">
										• {{ translate(code_name) }} <br>
									</span>
								</b-alert>
							</div>
							<p
								v-if="thereIsExchange"
								class="exchange-text text-right small mt-2 px-2">
								{{ translate('exchange_disclaimer') }}
							</p>
						</li>
						<li
							v-if="paymentMethod.name !== 'checkmo' && VERIFY_PHONE_COUNTRIES.includes(country)"
							class="list-group-item h-auto py-3">
							<template v-if="!loadingVerification">
								<template v-if="verify === 'verify' && !verification.token">
									<h5><b>{{ translate('mobile_verification') }}</b></h5>
									<form
										@submit.prevent="handleVerification"
										@keydown="clearError($event.target.name);">
										<p v-if="stepMobileVerification === 'verify_mobile_number'">
											{{ translate('verify_mobile_number_description') }}
										</p>
										<label
											v-if="stepMobileVerification === 'verify_mobile_number'"
											for="mobile_number">
											{{ translate('mobile_number') }}
											<span class="text-danger">*</span>
										</label>
										<vue-tel-input
											v-if="stepMobileVerification === 'verify_mobile_number'"
											id="mobile_number"
											v-model="verification.mobile_number"
											:required="true"
											:placeholder="`${translate('mobile_number')}`"
											:class="errors.errors['mobile_number'] ? 'is-invalid' : ''"
											v-bind="bindProps"
											type="number"
											name="mobile_number"
											class="form-control rounded-1"
											@dataChanged="verification.mobile_number = $event" />
										<template v-if="errors.errors['mobile_number']">
											<span
												v-for="error in errors.errors['mobile_number']"
												:key="error"
												class="invalid-feedback animated fadeIn"
												v-text="error" />
										</template>
										<label
											v-if="stepMobileVerification === 'verify_mobile_code'"
											for="verification_code"
											class="small">
											{{ translate('mobile_code_sent', { number: maskedMobileNumber }) }}
										</label>
										<input-text
											v-if="stepMobileVerification === 'verify_mobile_code'"
											id="verification_code"
											:label="translate('verification_code')"
											:setter-value="verification.verification_code"
											:errors="errors.errors['verification_code']"
											:required="true"
											name="verification_code"
											type="text"
											@dataChanged="verification.verification_code = $event" />
										<div class="text-right my-2">
											<button
												v-if="stepMobileVerification === 'verify_mobile_code'"
												type="button"
												class="btn btn-secondary d-inline mr-2"
												@click.prevent="stepMobileVerification = 'verify_mobile_number'"
												@click="clearError('verification_code')">
												{{ translate('change_number') }}
											</button>
											<button
												:disabled="Object.keys(errors.errors).length > 0"
												type="submit"
												class="btn btn-primary d-inline">
												{{ stepMobileVerification === 'verify_mobile_number'? translate('send_code') : translate('verify') }}
											</button>
										</div>
									</form>
								</template>
								<template
									v-if="verify ==='verified' || verification.token"
									class="py-3">
									<b-row>
										<b-col class="text-center">
											<i class="fas mb-2 fa-4x mx-auto fa-check-circle success-icon" />
										</b-col>
									</b-row>
									<b-row>
										<b-col class="text-center">
											<p class="mb-0">
												<b>{{ translate('mobile_number_verified') }}</b>
											</p>
										</b-col>
									</b-row>
								</template>
							</template>
							<is-loading
								v-else
								:loading-label="translate('loading')"
								:no-data-label="translate('data_not_found')"
								:loading="loadingVerification"
								:has-data="true"
								class="mt-3" />
						</li>
					</div>
					<div class="col-12 align-self-end">
						<li
							class="h-auto border-0 px-0 pt-2 d-flex">
							<div class="form-check mt-2">
								<input
									id="policyNProcedures"
									v-model="disclaimer"
									name="policyNProcedures"
									class="form-check-input"
									type="checkbox"
									value="">
								<label
									class="form-check-label"
									for="policyNProcedures"
									v-html="translate('policies_and_procedures_agreement', {policies_and_procedures: `<a target='_blank' href='${TERMS_AND_CONDITIONS_REDIRECTION}'>${ translate('policies_and_procedures') }</a>`})" />
								<div>
									<p class="font-italic mb-0">
										{{ translate('autoship_policy') }}
									</p>
								</div>
							</div>
						</li>
						<div
							class="btn-group w-100"
							role="group">
							<b-button
								:disabled="!disclaimer || verify === 'verify'"
								variant="primary"
								size="lg"
								block
								class="mt-3"
								@click="goSuccess">
								{{ translate('confirm') }}
							</b-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { VueTelInput } from 'vue-tel-input';
import { MMMDY_FORMAT } from '@/settings/Dates';
import isLoading from '@/components/Loading';
import { TERMS_AND_CONDITIONS_REDIRECTION, TIMEZONE } from '@/settings/General';
import DocumentTitle from '@/mixins/DocumentTitle';
import { PRODUCTS_LINKS } from '@/settings/Products';
import {
	SHOW_SIGNATURES_BY_COUNTRY as countriesListForSignature,
	SHIPPING_METHOD_CODES as shippingMethods,
} from '@/settings/Shipping';
import { AVAILABLECC as availableCC } from '@/settings/CreditCard';
import { UNPROCESSABLE } from '@/settings/Errors';
import { VERIFY_PHONE_COUNTRIES } from '@/settings/PhoneVerification';
import InputText from '@/components/InputText';
import Cart from '@/util/Cart';
import Country from '@/util/Country';
import PhoneVerification from '@/util/PhoneVerification';
import WindowSizes from '@/mixins/WindowSizes';
import { REQUIRED_NIN_COUNTRIES } from '@/settings/Profile';
import CommonMix from './mixins/Common';
import PackMix from './mixins/Package';

export default {
	name: 'RegisterOldConfirmation',
	components: { isLoading, VueTelInput, InputText },
	mixins: [CommonMix, DocumentTitle, WindowSizes, PackMix],
	data() {
		return {
			cart: new Cart(),
			cartData: new Cart(),
			productsLinks: PRODUCTS_LINKS,
			alert: new this.$Alert(),
			stateData: new Country(),
			isCompany: '',
			disclaimer: '',
			sponsor: {
				id: '',
				name: '',
			},
			idempotencyId: '',
			form: {
				first_name: '',
				last_name: '',
				// gender: '',
				email: '',
				// birthdate: '',
				username: '',
				mobile_number: '',
				national_identification_number: '',
				tax_id: '',
				company: '',
			},
			shipping: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				region_id: '',
				country_code: '',
				// signature_required: '',
			},
			billing: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				region_id: '',
				country_code: '',
			},
			paymentMethod: {
				name: '',
				card_type: '',
				last_digits: '',
			},
			shippingMethod: {
				shipping_method: '',
			},
			// productsQty: {},
			MMMDY_FORMAT,
			countriesListForSignature,
			TERMS_AND_CONDITIONS_REDIRECTION,
			timezone: TIMEZONE,
			packageName: '',
			bindProps: {
				inputOptions: {
					showDialCode: true,
				},
				disabledFetchingCountry: true,
			},
			verification: {
				mobile_number: '',
				verification_code: '',
				token: '',
			},
			VERIFY_PHONE_COUNTRIES,
			UNPROCESSABLE,
			phoneVerification: new PhoneVerification(),
			stepMobileVerification: 'verify_mobile_number',
			verify: 'no',
			requiredNinCountries: REQUIRED_NIN_COUNTRIES,
			shippingMethods,
		};
	},
	computed: {
		imageConfirmation() {
			return 'confirmation.jpg';
		},
		loadingCartTotals() {
			try {
				return this.cart.data.loading;
			} catch (error) {
				return true;
			}
		},
		cartTotals() {
			try {
				return this.cart.data.response.data.data.attributes;
			} catch (error) {
				return {};
			}
		},
		hasCartTotals() {
			return !!Object.keys(this.cartTotals);
		},
		thereIsExchange() {
			const theresExchange = this.products.find((item) => item.attributes.exchange);
			return typeof theresExchange !== 'undefined';
		},
		autoship() {
			try {
				const { products } = this.getStepInformation(Object.keys(this.steps).find((item) => this.steps[item].validation === 'shipping')).shipping.autoship;
				const autoshipProducts = [];
				Object.keys(products).forEach((sku) => {
					if (Number(products[sku]) > 0) {
						const [product] = this.products.filter((item) => item.attributes.sku === sku);
						autoshipProducts.push({
							code_name: product.attributes.code_name,
							qty: products[sku],
						});
					}
				});

				const response = {
					has_autoship_products: !!Object.keys(products).length,
					autoship_products: autoshipProducts,
				};

				return response;
			} catch (error) {
				return {};
			}
		},
		cardLogo() {
			if (this.paymentMethod.card_type.length > 0 && availableCC.includes(this.paymentMethod.card_type)) {
				return this.paymentMethod.card_type;
			}
			return 'cc';
		},
		shippingMethodInfo() {
			try {
				const shippingMethod = { ...this.shippingMethods[this.shippingMethod.shipping_method] };
				shippingMethod.name = this.shippingMethod.shipping_method;
				return shippingMethod;
			} catch (error) {
				return {};
			}
		},
		deliveryDays() {
			try {
				const deliveryDays = this.shippingMethodInfo.delivery_days;
				return deliveryDays[this.country] ? deliveryDays[this.country] : [];
			} catch (error) {
				return [];
			}
		},
		shippingNotice() {
			try {
				const shippingNotice = this.shippingMethodInfo.shipping_notice;
				return shippingNotice[this.country] ? shippingNotice[this.country] : shippingNotice.default;
			} catch (error) {
				return [];
			}
		},
		errors() {
			return this.phoneVerification.data.errors;
		},
		loadingVerification() {
			return this.phoneVerification.data.loading;
		},
		maskedMobileNumber() {
			const mobileRegexp = /([\d][\d]$)+/;
			return `******${this.verification.mobile_number.match(mobileRegexp)[0]}`;
		},
		cartInfo() {
			try {
				return this.cartData.data.response.data.response;
			} catch (error) {
				return [];
			}
		},
		loadingCartInfo() {
			try {
				return this.cartData.data.loading;
			} catch (error) {
				return false;
			}
		},
	},
	created() {
		this.bindProps.defaultCountry = this.country;
		this.bindProps.onlyCountries = [this.country];
		this.idempotencyId = this.createUuid();
		this.loadProductsInfo();
		Object.keys(this.steps).forEach((key) => {
			if (key !== this.$route.name) {
				const previous = this.getStepInformation(key);
				if (key === 'RegisterOldProduct') {
					if (typeof previous.product === 'undefined') {
						this.$router.replace({ name: key });
						return null;
					}
					// const { products } = previous.product;
					this.packageName = previous.product.package_code_name;
					// this.productsQty = products;
				} else if (key === 'RegisterOldPersonalInformation') {
					const { sponsor, personal_information: personalInformation } = previous;
					if (typeof sponsor === 'undefined' || typeof personalInformation === 'undefined') {
						this.$router.replace({ name: key });
						return null;
					}
					this.getSponsorInfo(sponsor).then((response) => {
						Object.keys(this.sponsor).forEach((value) => {
							this.sponsor[value] = response[value];
						});
					});
					this.isCompany = personalInformation.is_company;
					Object.keys(this.form).forEach((property) => {
						if (personalInformation[property] !== '') {
							this.form[property] = personalInformation[property];
							if (property === 'national_identification_number' || property === 'tax_id') {
								this.form[property] = this.form[property].replace(/.(?=.{4,}$)/g, '•');
							}
						}
					});
				} else if (key === 'RegisterOldShipping') {
					try {
						const { shipping_address: shippingAddress } = previous.shipping;
						if (typeof shippingAddress === 'undefined') {
							this.$router.replace({ name: key });
							return null;
						}
						const shippingMethod = previous.shipping_data;
						Object.keys(shippingMethod).forEach((element) => {
							this.shippingMethod[element] = shippingMethod[element];
						});
						Object.keys(shippingAddress).forEach((element) => {
							this.shipping[element] = shippingAddress[element];
						});
						this.stateData.getState(this.shipping.region_id).then((state) => {
							this.shipping.region_id = state.attributes.name;
						});
						this.shipping.country_code = this.shipping.country_code.toLowerCase();
					} catch (error) {
						return '';
					}
				} else if (key === 'RegisterOldPaymentMethod') {
					try {
						const { billing: billingAddress } = previous.payment;
						if (typeof billingAddress === 'undefined') {
							this.$router.replace({ name: key });
							return null;
						}
						const paymentMethod = { ...this.getStepInformation(Object.keys(this.steps).find((item) => this.steps[item].validation === 'payment')).card_data };
						Object.keys(paymentMethod).forEach((element) => {
							this.paymentMethod[element] = paymentMethod[element];
						});
						this.paymentMethod.name = previous.payment.payment_method.name;
						Object.keys(billingAddress).forEach((element) => {
							this.billing[element] = billingAddress[element];
						});
						this.stateData.getState(this.billing.region_id).then((state) => {
							this.billing.region_id = state.attributes.name;
						});
						this.billing.country_code = this.billing.country_code.toLowerCase();
					} catch (error) {
						return '';
					}
				}
			}
			return null;
		});
		if (this.shoppingCartId !== 'undefined' && this.shoppingCartId !== '') {
			this.cart.getCart(this.shoppingCartId, { is_register: 1 }, 'register_old');
			this.cartData.getCartInfo(this.shoppingCartId);
		}
		try {
			this.verify = 'no';
			if (this.paymentMethod.name !== 'checkmo') {
				this.verify = this.VERIFY_PHONE_COUNTRIES.includes(this.country) ? 'verify' : 'no';
				this.verification.mobile_number = this.form.mobile_number;
				this.getStoredVerification();
			}
		} catch (error) {
			if (process.env.NODE_ENV !== 'production') {
				console.warn(error);
			}
		}
	},
	methods: {
		getStoredVerification() {
			if (Object.keys(this.getStepInformation()).length > 0) {
				this.verification.token = this.getStepInformation().token;
				this.verify = 'verified';
			}
		},
		goSuccess() {
			let payload = {};
			Object.keys(this.steps).forEach((key) => {
				if (key !== this.$route.name) {
					if (key === 'RegisterOldProduct') {
						payload = { ...payload, ...this.getStepInformation(key) };
						payload.sponsor = this.sponsor.id;
					} else if (key === 'RegisterOldPersonalInformation') {
						payload = { ...payload, ...this.getStepInformation(key) };
						payload.sponsor = this.sponsor.id;
					} else if (key === 'RegisterOldShipping') {
						payload = { ...payload, ...this.getStepInformation(key) };
					} else if (key === 'RegisterOldPaymentMethod') {
						payload = { ...payload, ...this.getStepInformation(key) };
						payload.payment.payment_method.idempotency_id = this.idempotencyId;
					}
				}
			});
			payload.verification_phone_token = this.verification.token;
			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('processing_request'), this.translate('white_util_checkout'), options);
			const validation = { ...payload, step: this.steps[this.$route.name].validation };
			this.validateStep(validation).then(() => {
				this.removeRegister().then(() => {
					this.$store.dispatch('position/removeRegister');
					this.$router.replace('success');
					this.alert.close();
				}).catch(() => {});
			}).catch((error) => {
				this.idempotencyId = this.createUuid();
				if (error.status === 422) {
					const { payex } = error.errors;
					const { leg } = error.errors;
					if (typeof payex !== 'undefined') {
						let response = '';
						payex.forEach((item) => { response += `${item} <br>`; });
						this.alert.error(this.translate('something_went_wrong'), response, true);
					} else if (typeof leg !== 'undefined') {
						const alertConfig = {
							icon: 'error',
							title: this.translate('something_went_wrong'),
							text: this.translate('invalid_position'),
							showCancelButton: false,
						};
						this.alert.preConfirm(alertConfig, () => {
							this.$router.replace({ name: 'BinaryTree' });
							this.alert.close();
						});
					} else {
						this.alert.toast('error', this.translate('something_went_wrong'), { timer: 6000 });
					}
					const { cart_id: cartId } = error.errors;
					if (typeof cartId !== 'undefined') {
						this.saveCart(cartId);
					}
				} else if (error.status === 500) {
					this.alert.toast('error', this.translate('something_went_wrong'), { timer: 2500 });
					setTimeout(() => { this.$router.replace({ name: Object.keys(this.steps)[0] }); }, 2500);
				} else if (error.status === 423) {
					const alertConfig = {
						icon: 'error',
						title: this.translate('something_went_wrong'),
						text: this.translate('invalid_position'),
						showCancelButton: false,
					};
					this.alert.preConfirm(alertConfig, () => {
						this.$router.replace({ name: 'BinaryTree' });
						this.alert.close();
					});
				}
			});
			return null;
		},
		productHasLink(key) {
			return typeof this.productsLinks[key] === 'string' || this.productsLinks[key] instanceof String;
		},
		handleVerification() {
			if (this.stepMobileVerification === 'verify_mobile_number') {
				this.getCaptchaToken('register_verification').then((response) => {
					this.phoneVerification.verifyPhone({ mobile_number: this.verification.mobile_number, 'g-recaptcha-response': response }).then((verificationResponse) => {
						if (verificationResponse.response.status === 'sent') {
							this.stepMobileVerification = 'verify_mobile_code';
							this.alert.toast('success', this.translate('sms_send_success'));
						} else if (verificationResponse.response.status === 'whitelisted') {
							this.alert.toast('success', this.translate('mobile_number_verication_success'));
							this.verification.token = verificationResponse.response.token;
							this.verify = 'verified';
							this.saveStep({ token: this.verification.token });
						}
					}).catch(() => {
						if (!UNPROCESSABLE.includes(this.errors.status)) {
							this.alert.toast('error', this.translate('default_error_message'));
						}
					});
				});
			} else if (this.stepMobileVerification === 'verify_mobile_code') {
				this.getCaptchaToken('register_verification').then((response) => {
					const payload = {
						mobile_number: this.verification.mobile_number,
						verification_code: this.verification.verification_code,
						'g-recaptcha-response': response,
					};
					this.phoneVerification.verifyPhoneCode(payload).then((verificationResponse) => {
						this.verify = 'verified';
						this.alert.toast('success', this.translate('mobile_number_verication_success'));
						this.verification.token = verificationResponse.response.token;
						this.saveStep({ token: this.verification.token });
					}).catch(() => {
						if (!UNPROCESSABLE.includes(this.errors.status)) {
							this.alert.toast('error', this.translate('default_error_message'));
						}
					});
				});
			}
		},
		clearError(field) {
			if (field) {
				delete this.phoneVerification.data.errors.errors[field];
			}
		},
	},
};
</script>
<style>
	.text-small {
		font-size: 0.75em;
	}
</style>
<style scoped>
.success-icon::before {
	color: #64B64B !important;
}
</style>
